@import '../../styles/scss/variables.scss';

:root {
    --subtle-drop-shadow: 0px 1px 2px 0px rgba(10, 13, 18, 0.2);
    --light-green: #F5FCFB;
    --dark-green: #004757;
    --washed-green: rgb(128, 174, 173);
    --gray-0: #e9e9e9;
    --gray-1: #D5D7DA;
    --gray-2: #535862;
    --gray-3: #323838;
    --gray-4: #002F2E;

    --orange: #FF8427;
    --dark-orange: #FF5E00;
}

.analyzer {

    background-color: white;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    body,
    div,
    span,
    p,
    a,
    input,
    select {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
    }

    a:hover {
        text-decoration: none;
    }

    select,
    input[type="text"],
    input[type="password"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 12px;
        border: 1px solid var(--gray-0);
        outline: 0px;
        border-radius: 4px;
    }

    select {
        padding-right: 32px;
        background-image: url('../../static/chevron-down.svg');
        background-repeat: no-repeat;
        background-position: right 8px center;
    }

    h1 {
        font-family: 'Inter', Helvetica, sans-serif;
        text-align: center;
        color: white;
        font-weight: 700;
        font-size: 36px;
    }

    button {
        font-weight: 600;
        padding: 12px;
        border-radius: 8px;
        background-color: white;
        border: 1px solid var(--gray-1);
        color: var(--gray-2);
    }

    .button {
        font-weight: 500;
        padding: 8px;
        border-radius: 4px;
        border: 1px solid var(--gray-3);
        color: var(--gray-3);
        display: inline-block;
        min-width: 120px;
        text-align: center;
    }

    .pill {
        font-size: 14px;
        padding: 4px 14px;
        border-radius: 14px;

        @media (max-width: $sm) {
            font-size: 12px;
        }
    }

    .dark-green {
        background-color: var(--dark-green);
        border-color: white;
        color: white;
        transition: all 0.15s ease-in-out;
    }

    .dark-green:hover {
        background-color: #003945;
    }

    .dark-orange {
        background-color: var(--dark-orange);
        border-color: var(--dark-orange);
        color: white;
        transition: all 0.15s ease-in-out;
    }

    .dark-orange:hover {
        background-color: #E65400;
        border-color: #E65400;
    }

    .light {
        border-color: var(--gray-0);
    }

    .green-text {
        color: #005E5C;
    }

    .red-text {
        color: #DA5151;
    }

    .red {
        background-color: white;
        color: #E11C1C;
    }

    .landing-container {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    .fixed-layout-inner-scroll {
        height: calc(100vh - 72px);
        display: flex;
        flex-direction: column;
    }

    .fixed-height-content {
        z-index: 99;
        height: calc(100% - 50px);
        display: flex;
        flex-direction: row;

        main {
            flex-basis: 100%;
            height: 100%;
            overflow-y: auto;
        }
    }

    .content-box {
        padding: 16px;
        background-color: white;
        border: 1px solid rgb(244, 244, 244);
        border-radius: 8px;
    }

    .top-nav {
        background-color: white;
        padding: 15px 24px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        position: relative;
        box-shadow: 4px 0px 10px 0px #0000000D;
        height: 72px;

        a {
            margin-right: 12px;
        }
    }

    .logo,
    .menu-toggle {
        z-index: $zindex-fixed;
    }

    .menu-toggle {
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        padding: 0px;
        outline: none;
    }

    .drawer-menu {
        position: fixed;
        top: 0px;
        right: -100%;
        width: 100%;
        height: 100vh;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: right 0.15s ease-in-out;
        z-index: 1000;
    }

    .drawer-menu.open {
        right: 0;
    }

    .menu-items {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .close-btn {
        position: absolute;
        top: 20px;
        right: 30px;
        background: none;
        border: none;
        color: white;
        font-size: 28px;
        cursor: pointer;
    }

    span.sparkles {
        color: transparent !important;
        text-shadow: 0 0 0 white;
    }

    .landing-above-fold {
        padding: 90px;
        text-align: center;
        background: radial-gradient(50% 50% at 50% 50%, rgba(0, 71, 87, 0.9) 0%, rgba(0, 65, 80, 0.85) 22%, rgba(0, 55, 70, 0.9) 100%);

        @media (max-width: $sm) {
            padding: 60px;
        }
    }

    .tagline {
        display: block;
        margin: 0 0 48px 0;
        color: white;
        font-size: 24px;
        font-weight: 400;

    }

    .quick-summary-explanation {
        color: white;
        display: block;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 12px;
    }

    .quick-summary-buttons {
        margin: 0 auto;
        max-width: 640px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 24px;
    }

    .quick-summary-buttons a {
        background-color: white;
        color: var(--gray-4);
        padding: 8px 32px;
        border-radius: 32px;
        box-shadow: var(--subtle-drop-shadow);
    }

    .quick-summary-buttons a strong {
        color: var(--dark-green);
        text-wrap: nowrap;
    }

    .quick-summary-buttons a.selected,
    .quick-summary-buttons a.selected strong {
        background-color: var(--dark-orange);
        color: white;
    }

    .search-and-summary {
        width: 90%;
        margin: auto;
        background-color: white;
        padding: 42px 0;
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: center;
        text-align: center;
        align-items: center;

        .search-controls {
            @media (max-width: $md) {
                flex-direction: column;
                align-items: stretch;
                width: 100%;
            }
        }
    }

    .search-and-summary span {
        font-size: 18px;
        color: var(--dark-green);
    }

    @media (max-width: $sm) {
        .search-and-summary {
            font-size: 16px;
        }

        .search-and-summary span {
            font-size: 16px;
        }
    }

    .search-controls {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        .dropdown-button {
            height: auto;
            padding: 12px;
            font-size: 16px;
            border-radius: 4px;
            color: var(--gray-2);
        }

        @media (max-width: $sm) {
            flex-direction: column;
            align-items: stretch;
        }
    }

    .search-controls input {
        background-image: url('../../static/search.svg');
        background-repeat: no-repeat;
        background-position: left 8px center;
        box-sizing: border-box;
        border: 1px solid var(--gray-0);
        padding: 12px 12px 12px 32px;
    }

    .search-controls a {
        box-shadow: var(--subtle-drop-shadow);
        white-space: nowrap;
    }

    .two-column-section {
        max-width: 1000px;
        margin: 40px auto;
        display: flex;
        flex-flow: row nowrap;
        gap: 24px;
        padding: 0px 20px;

        div {
            flex-basis: 50%;
            flex-grow: 1;
        }

        @media (max-width: $sm) {
            flex-direction: column;
        }
    }

    .landing-below-fold {
        max-width: 1000px;
        margin: 40px auto;
        display: flex;
        flex-flow: row nowrap;
        gap: 12px;

        h2 {

            font-size: 24px;
            font-weight: 700;
            margin-bottom: 24px;
            color: var(--dark-green);
        }

        h3 {
            font-weight: 700;
            margin-top: 20px;
            color: var(--orange);
            margin-bottom: 24px;
        }

        .right {
            max-width: 480px;
            margin: 0 auto;
        }

        ol {
            list-style: none;
            counter-reset: numList;
        }

        li {
            margin-top: 36px;
        }

        // A few tricks to get styled number markers
        li:before {
            // We increment a counter and make that the content of this pseudo-element
            counter-increment: numList;
            content: counter(numList);
            // Negative margin re-aligns the list
            margin-left: -24px;
            // Relative position moves the list marker away from the item text
            position: relative;
            right: 8px;
            // Defined height/width
            display: inline-block;
            width: 24px;
            height: 24px;

            //Align text and add circle as border
            text-align: center;
            line-height: 24px;
            border-radius: 999px;

            background: var(--dark-green);
            color: white;
            font-size: 14px;
        }


    }

    .trusted-by {
        background-color: white;
        text-align: center;
        padding: 24px 0;

        div {
            font-weight: 700;
            margin-bottom: 24px;
        }

        img {
            width: 100%;
            max-width: 1100px;
        }
    }

    .features-benefits {
        padding: 48px;
        background-color: var(--dark-green);
        color: white;

        h2,
        .color-emphasis {
            text-align: center;
            font-size: 24px;
            font-weight: 500;
        }

        h3 {
            font-size: 22px;
            font-weight: 500;
        }

        .color-emphasis {
            color: #FFCA4C;
        }

        .buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .button {
            max-width: 300px;
            margin: 5px;
        }

        @media (max-width: $md) {
            padding: 36px 20px;

            h2,
            .color-emphasis {
                text-align: center;
                font-size: 18px;
                font-weight: 500;
            }

            h3 {
                font-size: 18px;
                font-weight: 500;
            }
        }

        @media (max-width: $sm) {
            h3 {
                text-align: center;
            }
        }
    }

    .faq {
        padding: 48px;
        background-color: white;

        h2 {
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: var(--dark-green);
            margin-bottom: 48px;
        }

        h3 {
            font-weight: 700;
            color: var(--dark-green);
            max-width: 1000px;
            margin: 36px auto 0 auto;
        }

        p {
            max-width: 1000px;
            margin: 8px auto;
        }

        @media (max-width: $sm) {
            padding: 36px;

            h2 {
                font-size: 22px;
            }

            p {
                font-size: 14px;
            }
        }
    }

    footer {
        margin-top: auto;
        padding: 14px 36px;
        background-color: var(--light-green);
        color: var(gray-4);
        text-align: center;
        font-size: 14px;
        height: 50px;

        a {
            color: var(--gray-4);
            text-decoration: underline;
            display: inline-block;
            margin-left: 24px;
        }
    }

    .search-results .bill-card {
        margin: 24px auto;
        max-width: 1020px;
        border-radius: 8px;
        border: 1px solid rgb(244, 244, 244);
        padding: 16px;
        background-color: white;
        display: flex;
        gap: 16px;
    }

    .search-results .bill-card .number {
        flex-grow: 0;
        font-weight: bold;
        white-space: nowrap;
    }

    .search-results .bill-card .name {
        flex-grow: 1;
    }

    .search-results .bill-card .status {
        text-transform: capitalize;
        align-self: start;
        text-align: center;
        flex: 0 0 100px;
    }

    .search-results .bill-card .session {
        white-space: break-spaces;
        flex: 0 0 100px;
    }

    .search-results .no-bill-results {
        max-width: 680px;
        margin: 0 auto 24px auto;
    }

    .search-results a,
    .search-results a:hover {
        text-decoration: none;
        color: black;
    }

    .search-results .error {
        width: 90%;
        margin: auto;
    }

    .search-results .search-loading-container {
        width: min-content;
        margin: auto;
    }

    @media (max-width: $md) {
        .search-results .bill-card {
            padding: 8px;
            gap: 8px;
            width: 92%;
        }

        .search-results .bill-card {
            font-size: 14px;
        }
    }

    @media (max-width: $sm) {
        .search-results .bill-card {
            padding: 6px;
            gap: 6px;
            width: 95%;
        }

        .search-results .bill-card {
            font-size: 13px;
        }

        .search-results .bill-card .status {
            font-size: 11px;
        }
    }

    .bill-status {
        text-transform: capitalize;
    }

    /* Bill Text Display, some of these rules ovverride main policy app styling */
    #billView {
        padding-top: 24px;

        .row {
            margin-right: 0;
            margin-left: 0;
        }

        .line-number-col {
            min-width: 2rem;
        }

        span {
            line-height: 2rem;
        }

        ins {
            color: #6aa84f;
            font-weight: 700;
            text-decoration: none;
        }

        del {
            color: red;
            font-weight: 700;
            text-decoration: line-through;
        }
    }

    .sidebar-content {
        height: 100%;
        display: flex;
        flex-direction: column;

        .mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 24px;
            justify-content: space-around;

            a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 42px;
                height: 50px;
                border: 1px solid #ccc;
                border-radius: 8px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                background-color: #fff;
                transition: all 0.3s ease-in-out;
                text-decoration: none;
                margin-bottom: 12px;
            }

            @media (max-width: $sm) {
                font-size: 20px;

                a {
                    width: 32px;
                    height: 40px;
                }
            }
        }
    }

    .left-nav {
        z-index: 100;
        height: 100%;
        padding: 32px 32px 100px 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 4px 0px 10px 0px #0000000D;
        position: relative;

        .toggle-drawer {
            width: fit-content;
        }

        .open {
            flex: 0 0 290px;
        }

        h2 {
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 60px;

            @media (max-width: 576px) {
                margin-bottom: 8px;
            }
        }

        img.logo {
            width: 117px;
        }

        @media (max-width: 576px) {
            padding: 12px 12px 100px 12px;
        }
    }

    .left-nav .callout-button {
        position: relative;
        display: block;
        width: 212px;
        height: 75px;
        padding: 12px;
        margin-bottom: 60px;
        background-color: var(--dark-green);
        color: white;
        border-radius: 4px;

        .link-arrow {
            position: absolute;
            right: 12px;
            top: 12px;
        }
    }

    .left-nav .links {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .left-nav .links a {
        color: var(--gray-4);
        text-decoration: underline;
    }

    .header-search-controls {
        width: 90%;
        margin: 48px auto 10px;
        display: flex;
        flex-direction: column;

        h3 {
            margin-bottom: 20px;
            font-size: 24px;
            font-weight: 500;
            color: var(--dark-green);
        }

        @media (max-width: $sm) {
            font-size: 14px;
            margin: 30px auto 0px;

            h3 {
                font-size: 20px;
            }
        }

    }

    .main-content {
        background-color: white;
    }

    .login-overlay {
        background-color: var(--washed-green);
        height: calc(100vh - 72px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .login-card-wrapper {
        position: relative;
        padding: 24px;
        box-sizing: border-box;
        width: 500px;
        margin: 0 auto;
        background-color: white;
        border-radius: 20px;

        @media (max-width: 576px) {
            padding: 8px;
            width: 95%;
        }
    }

    .login-card-wrapper .close-icon {
        float: right;
        width: 24px;
    }

    .login-card {
        padding: 48px;
        text-align: center;

        @media (max-width: 576px) {
            padding: 8px;
        }
    }

    .login-card a {
        color: var(--dark-green);
        text-decoration: underline;
    }

    .login-card h2 {
        text-align: center;
        color: var(--dark-green);
        font-size: 24px;
        font-weight: 700;
        margin-top: 12px;
        margin-bottom: 20px;
    }

    .login-card img {
        display: block;
        margin: 0 auto;
        width: 20px;
    }

    .login-card strong {
        color: var(--dark-green);
    }

    .login-card .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }

    .login-card .buttons .learn-more {
        background-color: var(--orange);
        color: white;
        padding: 8px;
        width: 253px;
        box-shadow: 0px 1px 3px 0px #0000004D;
        border-radius: 8px;
    }

    .login-card .login-button {
        border: 1px solid #666666;
        border-radius: 24px;
        width: 253px;
        padding: 8px;
        color: var(--gray-4);
        text-decoration: none;
    }

    .login-card .login-button.google {
        padding-left: 16px;
        background-image: url('../../static/google-icon.svg');
        background-repeat: no-repeat;
        background-position: left 12px center;
    }

    .login-card .process {
        /* remove this illustration to save space on mobile/laptops for now */
        display: none;
        padding-top: 24px;
        margin-bottom: 24px;
        /*display: flex;*/
        flex-direction: row;
        justify-content: space-around;

        span {
            font-size: 14px;
            color: #6C7979;
            text-transform: uppercase;
            max-width: 72px;

            img {
                margin-bottom: 8px;
            }
        }
    }

    .login-card .faq-link {
        text-decoration: underline;
        color: black;
    }

    .login-card .login-link {
        margin-bottom: 16px;
    }

    .login-card .terms {
        font-size: 14px;
        position: absolute;
        width: 100%;
        bottom: 8px;
        left: 0;
        text-align: center;
    }

    /* Nice little <hr>-style 'separator' with words in the middle
    of a horizontal line */
    .login-card {
        .separator {
            display: flex;
            align-items: center;
            text-align: center;
            margin-top: 8px;
            margin-bottom: 8px;
        }

        .separator::before,
        .separator::after {
            content: '';
            flex: 1;
            border-bottom: 1px solid #000;
        }

        .separator:not(:empty)::before {
            margin-right: .25em;
        }

        .separator:not(:empty)::after {
            margin-left: .25em;
        }
    }

    .login-form {

        input[type="text"],
        input[type="password"] {
            width: 100%;
            padding: 8px;
            margin-top: 8px;
        }

        input[type="checkbox"] {
            margin-right: 8px;
        }
    }

    .login-form .under-login-controls {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        font-size: 14px;
        justify-content: space-between;
    }

    .login-form #cf-turnstile {
        margin: 8px auto 0 auto;

    }

    .login-form .terms-acceptance {
        margin-top: 8px;
    }

    .login-form button {
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .login-form button:disabled {
        background-color: var(--gray-1);
    }

    .checkout-overlay {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 132px;
    }

    .settings-header {
        margin: 60px;
        text-align: center;
        color: var(--dark-green);

        @media (max-width: $sm) {
            font-size: 24px;
            margin: 30px 30px 0px;
        }
    }

    .settings-container {
        background-color: white;
        width: 90%;
        margin: 48px auto 0px;
    }

    .form-header,
    .form-container {
        max-width: 640px;
        margin: 0 auto;
    }

    .form-header h3 {
        font-size: 18px;
        font-weight: 600;
    }

    .form-header .subtitle {
        font-size: 14px;
        font-weight: 400;
        color: var(--gray-2);
        margin-bottom: 12px;
    }

    .form-container {
        border: 1px solid var(--gray-1);
        border-radius: 8px;
        box-shadow: var(--subtle-drop-shadow);
        padding: 12px 24px 24px 24px;
    }

    .form-container label {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        color: var(--gray-2);
    }

    .form-container input {
        padding: 8px;
    }

    .form-container .form-actions {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid;
        border-color: var(--gray-1);
        display: flex;
        flex-direction: row;
        gap: 8px;
        justify-content: end;
    }

    .form-container .success-message {
        color: var(--dark-green);
        text-align: right;
    }

    .dialog-actions {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid;
        border-color: var(--gray-1);
    }

    .active-subscription {
        .label {
            display: inline-block;
            min-width: 200px;
        }

        .value {
            font-weight: 600;
            font-size: 24px;
        }
    }

    .subscription-section {
        text-align: center;

        a {
            color: var(--dark-green);
            font-weight: bold;
        }
    }

    #success {
        width: 90%;
        max-width: 640px;
        margin: 48px auto;
        padding: 24px;
        border: 1px solid var(--gray-1);
        border-radius: 8px;
        box-shadow: var(--subtle-drop-shadow);

        @media (max-width: $sm) {
            font-size: 14px;
        }
    }

}