@import '../../styles/scss/variables.scss';

.analyzer {
    #loader {
        position: relative;
        width: 300px;
        min-height: 52px;
    }

    #loader img {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 52px;
        animation-name: fade;
        animation-iteration-count: infinite;
        animation-duration: 6s;
    }

    #loader .analyzing-text {
        position: relative;
        top: 14px;
        left: 50px;
        animation: blinkText 2s infinite alternate;
        font-size: 16px;
        position: relative;
        font-weight: 600;

        &::after {
            content: '';
            animation: blinkDots 2s infinite steps(1);
        }
    }

    #l1 {
        animation-delay: 0s;
    }

    #l2 {
        animation-delay: -4s;
    }

    #l3 {
        animation-delay: -2s;
    }

    .loadingContainer {
        max-width: 550px;
        margin-top: 25px;
        font-size: 14px;
        color: #212529;

        h3 {
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
        }

        ul {
            list-style-type: none;
            padding: 0px;
        }

        .step {
            margin-bottom: 20px;
            opacity: 1;
            transition: opacity 1s ease-in-out;
        }

        .blinking {
            animation: blinkText 2s infinite alternate;
        }

        .stepTitle {
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
            margin-bottom: 8px;
            opacity: 0;
        }

        .stepDetails {
            list-style-type: none;
            padding: 0;

            .detail-item {
                opacity: 0;
                transform: translateY(10px);
                transition: opacity 0.8s ease-in-out, transform 0.5s ease-in-out;
            }
        }

        .visible {
            opacity: 1;
            transform: translateY(0);
            animation: fadeIn 1s ease-in forwards;
        }

        .hidden {
            opacity: 0;
        }
    }

    @keyframes fade {
        0% {
            opacity: 0;
        }

        20% {
            opacity: 1;
        }

        33% {
            opacity: 1;
        }

        53% {
            opacity: 0;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes blinkDots {
        0% {
            content: '';
        }

        25% {
            content: '.';
        }

        50% {
            content: '..';
        }

        75% {
            content: '...';
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translateY(5px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes blinkText {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0.45;
        }
    }
}